import React from 'react'
import './single_hero.css'

function SingleHero(props) {
  return (
    <React.Fragment>
            <div style={{backgroundImage: `linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.6)) ,url(${props.backgroundImage})`}} className="SinglepageBackground">

                <h1 className='SinglePageHeading'>{props.title}</h1>


            </div>
    </React.Fragment>
  ) 
}

export default SingleHero