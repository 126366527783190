import React from "react";
import "./textContainer.css";

function TextContainer(props) {
  return (
    <React.Fragment>
      <div className="container-fluid textContainerOuter">
        <div
          style={{
            flexDirection: props.side === "left" ? "row-reverse" : "row",
          }}
          className="container textContainer"
        >
          <div className="leftContainer">
            <img
              src={props.imgUrl}
              alt="Avatar"
            />
          </div> 

          <div className="rightContainer">
            <h2 className="text__title">{props.title}</h2>
            <hr />

            <p className="text__desc">{props.description}</p>

            <a className="btn btn-warning" href="tel:778-319-1378 ">
              Call us Now
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TextContainer;
