import React from "react";
import "./hero.css";

function Hero() {
  return (
    <React.Fragment>
      <div className="Hero__background">
        {/*content   */}
        <h1 className="Hero__title">New Look Mechanical</h1>
        <h3 className="Hero__description">
          Don't Sleep With a Drip, Call your Plumber
        </h3>

        <div className="Hero__buttonContainer">
          <a className="Hero__button btn btn-warning" href="tel: 778-319-1378">
            Call : 778-319-1378
          </a>
          <a
            href="mailto:info@newlookmechanical.ca"
            className="btn btn-warning Hero__button"
          >
            info@newlookmechanical.ca
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Hero;
