import React from "react";

//import Components
import Banner from "../components/banner/banner";
import ServiceList from "../components/services_only/serviceList.jsx";
import Form from "../components/Form/Form";
import Certification from "../components/certified/certified";

function Services() {
  return (
    <React.Fragment>
      <Banner />

      <ServiceList />

      <Certification />
      <Form />
    </React.Fragment>
  );
}

export default Services;
