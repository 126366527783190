import React from "react";
import Card from "../cards/Card";
import "./ourServices.css";

function OurServices() {
  return (
    <React.Fragment>
      <div className="ourServices">

      <h1 className="services_heading">Our Services</h1>

      <p className="services__desc">Handcrafted just for yoiu </p>
      <hr />
      <div className="cardContainer">

        
        {/* first  */}
        <div className="cardHolder">
          <Card imgUrl={"https://own-content.ownerly.com/fit-in/750x0/filters:format(jpeg)/filters:quality(60)/2020-01-22-Types-of-Heating-Systems-CDN.jpg"} title={"Heating"} desc={"Heating Services in Vancouver, Canada"} />
        </div>

        {/* first  */}
        <div className="cardHolder">
          <Card imgUrl={"https://trusteyman.com/wp-content/uploads/2019/02/how-does-plumbing-work-e1548696261445.jpeg"} title={"Plumbing"} desc={"Plumbing Services in Vancouver, Canada"} />
        </div> 

        {/* first  */}
        <div className="cardHolder">
          <Card imgUrl={"http://ssservices.in/wp-content/uploads/2021/10/hobrepair.jpg"} title={"Gas"} desc={"Gas Services in Vancouver, Canada"} />
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}

export default OurServices;
