import React from "react";
import './faq.css'


function FAQ() {
  return (
    <React.Fragment>
      <div className="faqHeadingDiv">
        <h1 className="FAQh1">Frequently Asked Questions</h1>
        <h3 className="faqSubHeading">😃</h3>
        <hr />
      </div>

      {/* FAQ starts */}

      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Why you should never unclog your drains without a plumber ?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                When you’re dealing with minor clogs, it’s possible that you are
                thinking about how DIY remedy will work to clear your pipes.
                However, do-it-yourself methods of drain cleaning are never a
                completely viable solution to your plumbing problems.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is Hydro Jetting?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Hydro Jetting does not use chemicals. By forcing water through
                your pipes at nearly 4000 PSI, the Cole Plumbing team can remove
                buildup, debris, and tree roots. The water is sprayed at an
                extremely high pressure and flushes away the debris, allowing
                water and waste to flow through your plumbing freely.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How do I avoid clogged pipes?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Clogged pipes can be a major inconvenience. Whether it’s a
                slow-draining tub or a severe blockage in your main sewer line,
                addressing the problem immediately will help prevent further
                damage. The best way to prevent clogged pipes is to be mindful
                of what you put down your drains and toilet.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FAQ;
