import React, { useState, useEffect } from "react";
import Axios from "../../axios.js";

// import photoArray from "./images";
import "./gallery.css";
import { AiFillCopyrightCircle } from "react-icons/ai";
import {FiArrowLeftCircle} from 'react-icons/fi'
import {FiArrowRightCircle} from 'react-icons/fi'

import { ImCross } from "react-icons/im";

function Gallery(props) {
  const [photoArray, setphotoArray] = useState([]);
  const [lightbox, setlightBox] = useState(false);
  const [lightboxImage, setLightBoxImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    Axios.get("/photos")
      .then((responce) => {
        setphotoArray(responce.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePhotoClick = (item) => {
    setLightBoxImage(item.imgUrl.asset.url);
    setlightBox(true);
    // setCurrentIndex(photoArray.findIndex(item));
  };

  const handleClose = () => {
    setlightBox(false);
  };

  const handlePrevious = () => {
    if (currentIndex >= 0) {
      // Increasing the index as we click on the buttons
      if (currentIndex <= 0) {
        setCurrentIndex(photoArray.length);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    }

    setLightBoxImage(photoArray[currentIndex].imgUrl.asset.url)


  }

  const handleNext = () =>{
    if (currentIndex >= 0) {
      // Increasing the index as we click on the buttons
      if (currentIndex >= photoArray.length) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    
      setLightBoxImage(photoArray[currentIndex].imgUrl.asset.url);
    }
  }

  
  return (
    <React.Fragment>
      {lightbox ? (
        <div className="lightbox">
          <ImCross className="lightBoxCross" onClick={() => handleClose()} />
          <FiArrowLeftCircle className="lightBoxLeft" onClick={()=>handlePrevious()}/>
          <FiArrowRightCircle className="LightBoxRight" onClick={()=>handleNext()}/>
          <img src={lightboxImage} alt="LightboxImage" />
        </div>
      ) : null}

      <div className="Gallery">
        <div className="galleryHeading">
          <h1>Our Work</h1>
          <h3>A glance on our Hardship goes on and on</h3>
          <hr
            style={{
              height: "10px",
              color: "#024CCD",
              width: "20vw",
              borderRadius: "20px",
              margin: "auto",
            }}
          />
        </div>

        {/* ==================== Rendering Image s=============================== */}
        <div className="photos">
          {photoArray.map((item, index) => {
            return item.category.toLowerCase() === props.title.toLowerCase() ? (
              <img
                onClick={() => handlePhotoClick(item)}
                key={index}
                src={item.imgUrl.asset.url}
                alt={item.name}
                className="image"
              />
            ) : null;
          })}
        </div>

        {/* {copyright } */}
        <p style={{ padding: "30px" }}>
          <span>
            <AiFillCopyrightCircle />
          </span>
          All the above images cannot be used without our permission
        </p>
      </div>
    </React.Fragment>
  );
}

export default Gallery;
