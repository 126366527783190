const Questions  = [
    {
        title : "plumbing",
        Question : "I am getting no hot water from my electric water heater.",
        answer : "The high temperature cutoff may have been tripped, and may need to be reset. Otherwise it’s generally a faulty heating element or thermostat.",
        number : "One"
    },
    {
        title : "plumbing",
        Question : "I have poor water pressure at my kitchen sink faucet.",
        answer : "Try removing the faucet aerator at the end of the spout and cleaning the screen of any dirt and debris. The faucet aerator is designed to diffuse the water to prevent splashing, but it can become clogged over time.",
        number : "Two"
    },
    {
        title : "plumbing",
        Question : "Why does my water smell like rotten eggs?",
        answer : "There can be various reasons for this issue, but the most common is the anode degredation of your water heater",
        number : "Three"
    },

    // Heating 
    {
        title : "heating",
        Question : "When is the best time to replace my furnace?",
        answer : "Don’t wait until your furnace dies to consider replacing it. In an emergency—usually in the dead of winter—you’ll be in such a rush to restore heat that you might not carefully weigh all your options for service and savings, plus it will be more difficult to schedule an install in a timely manner",
        number : "One"
    },
    {
        title : "heating",
        Question : "What are the warning signs that my heating system is failing?",
        answer : "Systems more than 10 years old will probably show some signs of slowing down and losing efficiency. That’s not to say it can’t last another 10 years if you do regular maintenance and minor repairs, but watch for slower warming, reduced airflow, higher energy bills, strange odors, and loud noises.",
        number : "Two"
    },
    {
        title : "heating",
        Question : "My furnace is acting up again—should I fix it or replace it?",
        answer : "As a general guideline, consider replacement if the equipment is beyond three-quarters of its life expectancy and repairs will cost more than a third of replacement. Also consider the cost to operate it each month, because a newer, more efficient unit could actually save you money over time.",
        number : "Three"
    },


    // Gas services 

    {
        title : "Gas",
        Question : "What type of indoor home appliances use natural gas?",
        answer : "So many! Including ovens, stoves, fireplaces, water heaters, space heaters and clothes dryers.",
        number : "One"
    },
    {
        title : "Gas",
        Question : "Is it better to cook with natural gas or electric?",
        answer : "There’s a reason “cooking with gas” is an expression for “getting things done well”. Natural gas cooking provides easy temperature control, quick heating, self-ignition, self-cleaning and reduced costs. Most professional chefs prefer natural gas stoves and/or gas grills as the premium option for cooking excellent food.",
        number : "Two"
    },
    {
        title : "Gas",
        Question : "What are the benefits of using a natural gas fireplace?",
        answer : "Indoor and outdoor gas fireplaces and fire pits are easy to use – just press a button or flick a switch to immediately start a great fire. No worries about getting logs, stacking them, tending to the fire or cleaning up ashes.",
        number : "Three"
    }
]

export default Questions;