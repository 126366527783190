import React from "react";

// importing components
import Hero from "../components/Hero/Hero";
import TextContainer from "../components/TextContainer/textContainer";
import OurServices from "../components/ourServices/ourServices";
import CallToAction from "../components/Home_only/CTA/cta";
import Form from "../components/Form/Form";
import Certified from "../components/certified/certified";

function Home() {


  const subHeroTitle = "Experts in What you Expect";
  const subHeroDescription =
    "We have years of expirience in plumbing and Heating. We have the confidence to say that we are experts because we have earned this title by hard work. Any our your requirements related to pluming or renovation can be fulfilled by us. We Stand on Expectations";


  const lastTitleHome = "We Make Things Easy"
  const lastDescriptionHome =
    "For our Customers we make the process completely transparent , We use the parts that our customers want and try to use the best quality possible. Hence, leaving an impression of trust & honesty on our customer's Heart. This way we retain our Customers";


  return (
    <React.Fragment>
      <div className="HomePage">
        <Hero />
        <OurServices />
        <TextContainer title={subHeroTitle} description={subHeroDescription} imgUrl={"https://st2.depositphotos.com/1557108/8340/v/600/depositphotos_83403832-stock-illustration-plumber-mascot.jpg"}/>
      <CallToAction />
    <TextContainer title={lastTitleHome}  description ={lastDescriptionHome} side={"left"} imgUrl={"https://st2.depositphotos.com/2589681/8216/v/950/depositphotos_82168986-stock-illustration-plumber.jpg"}/>
    <Certified />


    <Form />
      </div>

    </React.Fragment>
  );
}

export default Home;
