import React from "react";
import './certified.css'

const Certified = () => {
  return (
    <React.Fragment>
      <div className="CertificationDiv">
        <h1>We are Certified Professionals</h1>
        <p>We got all the important Certifications Ready</p>
        <hr />
        <div className="CertificationContainer">
          <div className="Seal">
            <img
              src={require("../../resources/symbols/bonded.jpg")}
              alt="Bonded"
            />
          </div>
          <div className="Seal">
            <img
              src={require("../../resources/symbols/canada.jpeg")}
              alt="Canada"
            />
          </div>
          <div className="Seal">
            <img
              src={require("../../resources/symbols/licenced.jpeg")}
              alt="Licenced"
            />
          </div>
          <div className="Seal">
            <img
              src={require("../../resources/symbols/insured.jpeg")}
              alt="Insured"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Certified;
