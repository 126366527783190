import React from "react";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";


// importing Pages
import Home from "./Pages/Home.jsx";
import Services from "./Pages/Services";
import About from "./Pages/About";
import SinglePage from "./Pages/SinglePage/SinglePage";

// importing Required Compnents
import Navbar from "./components/navbar/navbar";
import Footer2 from "./components/footer/footer";

function App() {


  const SinglePages = [
    {
      title: "Plumbing",
      backgroundImage: "https://gsmplumbing.com.au/wp-content/uploads/bb-plugin/cache/Depositphotos_442581318_l-2015-1024x645-landscape.jpg",
    },
    {
      title: "Heating",
      backgroundImage: "https://www.venta-air.com/en_us/wp-content/uploads/2019/10/Home-Heating-2.jpg",
    },

    {
      title: "Gas",
      backgroundImage: "https://i.pinimg.com/originals/bb/aa/73/bbaa732787653b2aceba36ef2898b73e.jpg",
    },
  ];



  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/about" element={<About />} />

          {SinglePages.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Route
                  exact
                  path={`/${item.title}`}
                  element={<SinglePage backgroundImage={item.backgroundImage} title={item.title} />}
                />
              </React.Fragment>
            );
          })}
        </Routes>

      <Footer2 />
      </BrowserRouter>
    </div>
  );
}

export default App;
