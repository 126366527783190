import React from "react";
import "./footer.css";
import {
  AiTwotoneMail,
  AiFillGithub,
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlinePhone,
} from "react-icons/ai";

function Footer2() {
  return (
    <React.Fragment>
      <div className="mainFooter">
        <div className="upperSocial">
          <div className="leftupperName">
            <h4>
              Explore our all articles and projects by clicking on links down
              below
            </h4>
          </div>
          <div className="rightupperName">
            <a href="https://www.facebook.com/New-Look-Mechanical-Ltd-457511547741116/" className="me-4 text-reset">
              <AiFillFacebook />
            </a>
            <a href="/" className="me-4 text-reset">
              <AiFillTwitterCircle />
            </a>
            <a href="/" className="me-4 text-reset">
              <AiFillInstagram />
            </a>
            <a href="/" className="me-4 text-reset">
              <AiFillLinkedin />
            </a>
            <a href="/" className="me-4 text-reset">
              <AiFillGithub />
            </a>
          </div>
        </div>

        <div className="downFooter">
          <div className="left">
            <img
              className="Footerlogo"
              src={`${require("../../resources/logo.png")}`}
              alt="logo"
            />
          </div>
          <div className="middle">
            <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
            <p>
              <a href="https://newlookmechanical.ca" className="text-reset">
                Home
              </a>
            </p>
            <p>
              <a
                href="https://newlookmechanical.ca/services"
                className="text-reset"
              >
                Services
              </a>
            </p>
            {/* <p>
              <a
                href="https://newlookmechanical.ca/contact"
                className="text-reset"
              >
                Contact
              </a>
            </p> */}
            <p>
              <a
                href="https://newlookmechanical.ca/about"
                className="text-reset"
              >
                About
              </a>
            </p>
          </div>
          <div className="right">
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            {/* <p>
              <FaAddressBook /> Vancouver, Canada
            </p> */}
            <p>
              <a href="mailto:info@newlookmechanical.ca">
                <AiTwotoneMail />
                info@newlookmechanical.ca
              </a>
            </p>
            <p>
              <a href="tel: +1 778-319-13781">
                <AiOutlinePhone /> +1 778-319-1378
              </a>
            </p>
            <p>
              <a href="tel: +1 778-319-1378">
                <AiOutlinePhone /> +1 778-319-1378
              </a>
            </p>
          </div>
        </div>

        <div className="Copyright_div">
          {/* Copyright block */}
          <div className="copyright">
            <strong>© 2022 Copyright:</strong>
            <a
              className="text-reset fw-bold"
              href="https://newlookmechanical.ca/"
            >
              newlookmechanical.ca
            </a>
          </div>

          {/* Designed and Hosted by */}
          <div className="copyright">
            <strong>2022 Copyright:</strong>
            <a className="text-reset fw-bold" href="https://sliwebbuilder.ca/">
              sliwebbuilder.ca
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer2;
