import React from "react";
import Questions from "./FAQs.js";

import "./singleFAQ.css"

function SingleFAQ(props) {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingBottom: "80px",
          margin: "80px",
        }}
        className="container  SingleFAQDiv"
      >
        <h1 style={{ paddingBottom: "30px" }} className="FAQh1">
          Frequently Asked Questions
        </h1>
        <div
          style={{ border: "1px solid black" }}
          className="accordion accordion-flush"
          id="accordionFlushExample"
        >
          {Questions.map((item, index) => {
            return item.title.toLowerCase() === props.title.toLowerCase() ? (
              <div
                style={{ border: "1px solid black" }}
                key={index}
                className="accordion-item"
              >
                <h2
                  className="accordion-header"
                  id={`flush-heading${item.number}`}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${item.number}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${item.number}`}
                  >
                    {item.Question}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${item.number}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${item.number}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SingleFAQ;
