import React from 'react'
import './details.css'

import {AiFillPhone,AiTwotoneMail} from 'react-icons/ai'
import {FaRegAddressCard} from 'react-icons/fa'


function Details() {
  return (
    <React.Fragment>
        <div className="container Details">
            
            <div className="leftDetails">
                
                <h1>Our Details</h1>
 
                <a href="tel:+1778-319-1378"><p ><AiFillPhone /> +1 778-319-1378</p></a>
                <a href="mailto:info@newlookmechanical.ca"><p><AiTwotoneMail />info@newlookmechanical.ca</p></a>
                <p><FaRegAddressCard />2k 80 20Vancouver Canada</p>
            </div>

            <div className="rightDetails">
                <img src="https://previews.123rf.com/images/deklofenak/deklofenak1110/deklofenak111000027/10777543-a-happy-family-around-the-house.jpg" alt="" />
            </div>
        </div>
    </React.Fragment>
  )
}

export default Details