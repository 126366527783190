import React from "react";

// importing Components
import SingleHero from "../../components/SinglePage_only/Hero/single_hero";
import Gallery from "../../components/gallery/gallery";
import SingleFAQ from "../../components/SinglePage_only/Hero/FAQ/singleFAQ";
import Form from "../../components/Form/Form";

function SinglePage(props) {
  return (
    <React.Fragment>
      <SingleHero title={props.title} backgroundImage={props.backgroundImage} />
      <Gallery title={props.title} />
      <SingleFAQ title={props.title} />

      <Form />
    </React.Fragment>
  );
}

export default SinglePage;
