import React from "react";
import FAQ from "../components/FAQ/faq";
import "./style/About.css";

// importing components
import Ser from "../components/services_only/Service/ser";
import Form from "../components/Form/Form";
import Details from "../components/contactDetails/details";

function About() {
  const aboutUsDesc =
    "We are a plumbing Company based in Vancouver Canada, We are in this business for more than 10 years and we have done hundreds of big projects. You can count on Us for great work";
  return (
    <React.Fragment>

      <div className="AboutMain">
        {/* top Banner */}
        <div className="topAbout">
          <h1>About Us</h1>

          <div className="btnContainer">
            <a className="btn btn-primary" href="mailto:info@newlookmechanical.ca">
              Email us
            </a>
            <a className="btn btn-primary" href="tel:+1 778-318-1378">    
              Call :778-319-1378
            </a>
          </div>
        </div>
      </div>

      <div className="middleAbout">
        <Ser
          title={"Plumbing Company"}
          desc={aboutUsDesc}
          imgUrl={"https://wallpaperaccess.com/full/2491148.jpg"}
          btn={"nobtn"}
        />

        <Details />
        
      </div>

      {/* Faqs  */}
      <FAQ />

      {
        // My Directions
      }

      <div className="container">
          <h2 className="MapHeading">CheckOut Our Location</h2>
          <p style={{textAlign : "center"}}>😃</p>
        <iframe
        title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.247057885962!2d-123.17639794249646!3d49.19529703630279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548674ccb7189d71%3A0xbf7039d71797c1e0!2sFairmont%20Vancouver%20Airport!5e0!3m2!1sen!2sin!4v1651776291458!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{border:"0"}}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="GoogleMap"
        ></iframe>
      </div>

      {/* Form */}
      <div className="FormContainer">
        <Form />
      </div>
    </React.Fragment>
  );
}

export default About;
