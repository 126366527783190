import React from "react";
import "./cta.css";

function CallToAction() {
  return (
    <React.Fragment>
      <div className="CalltoAction">
        <h1 className="cta_title">
          Have a Plumbing project wheter its repair,
          <br /> Renovation or New Construction, New look Plumbing
          <br /> Can do it all!!
        </h1>
        {/* <h3 className="Hero__description">
        Don't Sleep With a Drip, Call your Plumber
      </h3> */}
        <div className="Hero__buttonContainer">
          <a href="tel:+1 778-319-1378" className="btn btn-danger Hero__button">
            Call Us Now
          </a>
\
        </div>
      </div>
    </React.Fragment>
  );
}

export default CallToAction;
