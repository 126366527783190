import React from "react";
import Ser from "./Service/ser";

import "./serviceList.css";
function Services() {
  return (
    <React.Fragment>
      <h1 className="ourServiceHeading">Our Services</h1>
      <h3 className="ourServicesSubHeading">
        {" "}
        We Cover all you household needs
      </h3>
      <hr />

      <div className="ServiceHolder">
        <Ser
          title={"Plumbing"}
          desc={
            "People think plumbing is easy and they can do it theirself, However the reality is a little Harsh. Like every other profession, finding the root cause of a problem is a skill which comes with expirience. We got the skills you need for your needs. Checkout now !!"
          }
          imgUrl={
            "https://ab-plumbing.com/wp-content/uploads/2021/12/focused-male-plumber-in-working-overall-fixing-sink-in-bathroom.jpg"
          }
        />

        <Ser
          side={"left"}
          title={"Heating"}
          desc={
            "Heating is essential in the continenet we live. Without heating its quite risky and uncomfortable. Doesn't matter if you need new Appliances or there's something to repair we can do it all easily"
          }
          imgUrl={
            "https://www.theargus.co.uk/resources/images/11896118.jpg?type=article-full"
          }
        />

        <Ser
          title={"Gas"}
          desc={
            "Gas jobs need a professional for handling the risky materials and equipments. Do not take risk by hiring a cheap technician for this purpose. only trust a verified professional. We have all the verifications completed"
          }
          imgUrl={
            "https://media.architecturaldigest.com/photos/59a4720b050e4c32e970db85/master/w_1761,h_2300,c_limit/mcgrath-2-guest-bathroom-tile.jpg"
          }
        />
      </div>
    </React.Fragment>
  );
}

export default Services;
