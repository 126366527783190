import React from "react";
import "./card.css";

import { Link } from "react-router-dom";

function Card(props) {
  return (
    <React.Fragment>
      <div className="card">
        <img src={props.imgUrl} alt="" className="card-img-top" />
        <div className="card-body">
          <h5 className="cardTitle">{props.title}</h5>
          <p className="card-text">{props.desc}</p>
          <Link to={`${props.title}`} className="btn btn-primary">
            Exlore More
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Card;
