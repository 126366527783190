import React from "react";
import { Link } from "react-router-dom";
import "./ser.css";

function Ser(props) {
  console.log(props.btn);
  return (
    <React.Fragment>
      <div
        style={{ flexDirection: props.side === "left" ? "row-reverse" : "row" }}
        className=" ServiceContainer"
      >
        <div className="leftContainer">
          <img src={props.imgUrl} alt="" />
        </div>
        <div className="rightContainer">
          <h1 className="SerH1">{props.title}</h1>
          <hr style={{ width: "50%" }} />
          <p className="SerDesc">{props.desc}</p>
          {props.btn === "nobtn" ? null : (
            <Link className="btn btn-primary" to={`/${props.title}`}>
              Explore
            </Link>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Ser;
